import React, { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { Locals_all } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import nanoid from 'nanoid';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiSelectMultiLangualDropdown } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import { CreateSkillFromLucaModal } from '~/components/Modals/CreateFromLucaModals';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { ISkillGenerated } from '~/pages/SkillSetsOverview/types';
import { ActionItemBlock, ActionsWrap } from '~/pages/SurveyUpdate/design';

import { Wrapper, GenerateLucaContainer } from './design';
import { StepDetails } from './StepDetails';
import { StepFocusAreas } from './StepFocusAreas';
import { StepJobProfiles } from './StepJobProfiles';
import { StepSummary } from './StepSummary';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm } from './types';
import type { ISkillCategory, ISkillTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

function SkillForm({
  languageState,
  formMethods,
  title,
  onSubmit,
  goBack,
  skillCategory,
  setSkillCategory,
  skillCategories,
  currentStatus = false,
  skillTemplate,
  onDelete,
}: {
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: ILanguageStateReturn;
  title: ReactNode;
  onSubmit: (data: IGeneralForm & { published: boolean }) => void;
  goBack: () => void;
  skillCategory: ISkillCategory | undefined;
  skillCategories: ISkillCategory[];
  setSkillCategory: (skillCategory?: ISkillCategory) => void;
  currentStatus?: boolean;
  skillTemplate?: ISkillTemplate;
  onDelete: () => void;
}) {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const [isLoading, _setIsLoading] = useState(false);
  const { handleSubmit, formState, watch, setValue, resetField } = formMethods;
  const [minHeight, setMinHeight] = useState<number>();
  const [openLucaModal, setOpenLucaModal] = useState(false);

  const skillName = watch('name')?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  );

  useEffect(() => {
    const nameError = formState.errors?.name;
    const categoryError = formState.errors?.skillCategory;
    const descriptionError = formState.errors?.description;
    const jobProfilesError = formState.errors?.jobProfiles;
    const focusAreasError = formState.errors?.focusAreas;

    if (jobProfilesError) {
      sectionState.setErrorSection(2, true);
    } else {
      sectionState.setErrorSection(2, false);
    }

    if (focusAreasError) {
      sectionState.setErrorSection(1, true);
    } else {
      sectionState.setErrorSection(1, false);
    }

    if (nameError || categoryError || descriptionError) {
      sectionState.setErrorSection(0, true);
    } else {
      sectionState.setErrorSection(0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors]);

  const sectionState = useSectionState([
    { title: i18n._(t`Skill or KPI details`) },
    { title: i18n._(t`Levels and focus area`) },
    { title: i18n._(t`Jobs that require this skill or KPI`) },
    { title: i18n._(t`Final check`) },
  ]);

  const onFail = () => {
    sectionState.setTriedToSubmit();

    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Please fill in all obligated fields`),
      type: TOAST_TYPES.INFO,
    });
    sectionState.goToFirstErrorSection();
  };

  const onSave = async (data: IGeneralForm) => {
    return onSubmit({ ...data, published: currentStatus });
  };

  const onPublish = async (data: IGeneralForm) => {
    return onSubmit({ ...data, published: true });
  };

  // Populate Skill from generated skill
  const onClickUsingAI = (generatedSkill: ISkillGenerated, selectedCategoryId: string) => {
    // Validate Category ID and update if necessary
    if (selectedCategoryId !== skillCategory?.id) {
      setSkillCategory(skillCategories.find((category) => category.id === selectedCategoryId));
      resetField('skillCategory');
      setValue('skillCategory', selectedCategoryId);
    }

    // Populate additional items
    resetField('name'); // These resetField are a workaround to trigger re-renders, as hook form register is not doing the trick
    setValue('name', turnMultiLangIntoArray(generatedSkill.name, languageState.companyLanguages));

    resetField('description');
    setValue(
      'description',
      turnMultiLangIntoArray(generatedSkill.description, languageState.companyLanguages),
    );

    const newFocusAreas: any[] = [];

    Object.keys(generatedSkill.focusAreas).forEach((locale) => {
      // Seed new focus area
      if (newFocusAreas.length === 0) {
        const size = generatedSkill.focusAreas[locale as Locals_all].length;
        new Array(size).fill(true).map((_, index) =>
          newFocusAreas.push({
            level: index,
            values: [],
          }),
        );
      }

      generatedSkill.focusAreas[locale as Locals_all].forEach((focusAreaGenerated, index) => {
        newFocusAreas[index].level = focusAreaGenerated.level;

        if (newFocusAreas[index].values.length === 0) {
          new Array(focusAreaGenerated.values.length).fill(true).map(() =>
            newFocusAreas[index].values.push({
              name: {},
              _id: nanoid(),
            }),
          );
        }

        focusAreaGenerated.values.forEach((value, i) => {
          newFocusAreas[index].values[i].name = {
            ...newFocusAreas[index].values[i].name,
            [locale as Locals_all]: value,
          };
        });
      });
    });

    setValue('focusAreas', newFocusAreas);
    setOpenLucaModal(false);
  };

  return (
    <>
      {openLucaModal && skillName && (
        <CreateSkillFromLucaModal
          skillName={skillName.value}
          onClose={() => setOpenLucaModal(false)}
          onClickManually={() => setOpenLucaModal(false)}
          onClickUsingAI={onClickUsingAI}
          defaultCategoryId={skillCategory?.id}
        />
      )}
      <DashboardHeader
        title={title}
        onBack={goBack}
        actions={
          <ActionsWrap>
            <GenerateLucaContainer>
              <Button
                label={i18n._(t`Generate with Luca AI`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.SECONDARY}
                icon={ICONS.AI_COLORED}
                onClick={() => setOpenLucaModal(true)}
                disabled={!skillName?.value || isLoading}
              />
            </GenerateLucaContainer>

            <ActionItemBlock>
              <MultiSelectMultiLangualDropdown
                languages={languageState.companyLanguages}
                setLanguages={languageState.setLanguages}
                primaryLanguage={languageState.companyPrimaryLanguage}
                preSelectedLanguages={languageState.languages}
                hideAction
              />
            </ActionItemBlock>

            <ActionItemBlock>
              <Tooltip size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Delete`)}>
                <span>
                  <Button
                    icon={ICONS.DELETE_BIN}
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.ICON}
                    isLoading={isLoading}
                    onClick={onDelete}
                  />
                </span>
              </Tooltip>
              {!currentStatus && (
                <Tooltip size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Save as Draft`)}>
                  <span>
                    <Button
                      icon={ICONS.SAVE}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.ICON}
                      isLoading={isLoading}
                      onClick={handleSubmit(onSave, onFail)}
                    />
                  </span>
                </Tooltip>
              )}
            </ActionItemBlock>
            <ActionItemBlock>
              <Button
                label={!currentStatus ? i18n._(t`Publish`) : i18n._(t`Save`)}
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
                onClick={handleSubmit(onPublish, onFail)}
              />
            </ActionItemBlock>
          </ActionsWrap>
        }
      />
      <Wrapper minHeight={minHeight}>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        <ShowSpinnerIfLoading loading={isLoading}>
          {sectionState.currentSection === 0 && (
            <StepDetails
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              setSkillCategory={setSkillCategory}
              skillTemplate={skillTemplate}
              skillCategories={skillCategories}
            />
          )}

          {sectionState.currentSection === 1 && (
            <StepFocusAreas
              formMethods={formMethods}
              setCurrentSection={sectionState.setCurrentSection}
              skillCategory={skillCategory}
              languageState={languageState}
              setMinHeight={setMinHeight}
            />
          )}

          {sectionState.currentSection === 2 && (
            <StepJobProfiles
              formMethods={formMethods}
              setCurrentSection={sectionState.setCurrentSection}
              skillCategory={skillCategory}
            />
          )}

          {sectionState.currentSection === 3 && (
            <StepSummary
              formMethods={formMethods}
              setCurrentSection={sectionState.setCurrentSection}
              onPublish={onPublish}
              onSave={onSave}
              onFail={onFail}
              skillCategory={skillCategory}
              languageState={languageState}
              currentStatus={currentStatus}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
    </>
  );
}

export { SkillForm };

import React, { useCallback, useMemo, useState } from 'react';

import { CAREER_PLAN_STATUSES, JOB_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import compareAsc from 'date-fns/compareAsc';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import { isEmpty } from 'lodash';
import every from 'lodash/every';
import { useDispatch } from 'react-redux';

import { ButtonVariant } from '~/components/Buttons';
import { confirm } from '~/components/ConfirmDialog';
import { ICONS } from '~/components/Icon';
import { AssignEmployeeModal } from '~/components/Modals/AssignEmployeeModal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { UserAvatar } from '~/components/UserAvatar';

import { Filters } from './filters';
import { NameRow, StatusLabel, StyledTableList } from './Members.design';
import { Title, TitleBlock } from './Setup.design';
import { StartEndDateModal } from './StartEndDateModal';

import { ColumnPosition, type IColumnTable } from '~/@types/table';
import { IMultiSelectOption } from '~/constants/userReviews';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { useMultiSelectState } from '~/hooks/useMultiSelectState';
import { usePagination } from '~/hooks/usePagination';
import {
  createCareerPlans,
  downloadCareerPlansCSV,
  removeCareerPlans,
} from '~/services/careerPlans';
import { removeCareerPlansFromReduxStore } from '~/store/careerPlans/actions';
import { COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

import { getJobStatus } from '../utils';

import type { ICareerPlanPopulated, IJobProfilePopulated } from '../types';
import type { I18n } from '@lingui/core';

enum CAREER_PLAN_SORT_OPTIONS {
  NAME_A_Z = 'nameAsc',
  NAME_Z_A = 'nameDesc',
  START_DATE_NEW_OLD = 'startDateAsc',
  START_DATE_OLD_NEW = 'startDateDesc',
  END_DATE_NEW_OLD = 'endDateAsc',
  END_DATE_OLD_NEW = 'endDateDesc',
  TIME_IN_ROLE_LEAST_MOST = 'timeInRoleAsc',
  TIME_IN_ROLE_MOST_LEAST = 'timeInRoleDesc',
  STATUS_ASC = 'statusAsc',
  STATUS_DESC = 'statusDesc',
}

function compareUndefinedDates(a: Date | undefined, b: Date | undefined) {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  return compareAsc(a, b);
}

function differenceInCalendarMonthsUndefined(a: Date | undefined, b: Date | undefined) {
  if (a === undefined && b === undefined) {
    return 0;
  }

  const today = new Date();
  return differenceInCalendarMonths(a || today, b || today);
}

function getJobStatusIndex(order = CAREER_PLAN_SORT_OPTIONS.STATUS_ASC) {
  const priority = {
    [CAREER_PLAN_SORT_OPTIONS.STATUS_ASC]: {
      [JOB_STATUS.PREVIOUS]: 1,
      [JOB_STATUS.ACTIVE]: 2,
      [JOB_STATUS.FUTURE]: 3,
    },
    [CAREER_PLAN_SORT_OPTIONS.STATUS_DESC]: {
      [JOB_STATUS.FUTURE]: 1,
      [JOB_STATUS.ACTIVE]: 2,
      [JOB_STATUS.PREVIOUS]: 3,
    },
  };

  return (a: ICareerPlanPopulated, b: ICareerPlanPopulated) => {
    // @ts-ignore
    const priorities = priority[order];
    return priorities[getJobStatus(a)] - priorities[getJobStatus(b)];
  };
}

function getStatusProps(endTime?: number, startTime?: number) {
  const today = new Date().getTime();

  if (endTime && endTime < today) {
    return {
      text: 'Previous',
      key: JOB_STATUS.PREVIOUS,
      borderColor: COLORS.PREVIOUS,
      backgroundColor: COLORS.STATUS_PREVIOUS_LIGHT,
    };
  }

  if (startTime && startTime < today && (!endTime || endTime > today)) {
    return {
      text: 'Active',
      key: JOB_STATUS.ACTIVE,
      borderColor: COLORS.ACTIVE,
      backgroundColor: COLORS.STATUS_ACTIVE_LIGHT,
    };
  }

  if (startTime && startTime > today) {
    return {
      text: 'Future',
      key: JOB_STATUS.FUTURE,
      borderColor: COLORS.STATUS_UPCOMING,
      backgroundColor: COLORS.STATUS_UPCOMING_LIGHT,
    };
  }

  return null;
}

const sortMethods: Record<
  CAREER_PLAN_SORT_OPTIONS,
  (a: ICareerPlanPopulated, b: ICareerPlanPopulated) => number
> = {
  [CAREER_PLAN_SORT_OPTIONS.NAME_A_Z]: (a, b) =>
    getUserFullName(a.createdFor).localeCompare(getUserFullName(b.createdFor)),
  [CAREER_PLAN_SORT_OPTIONS.NAME_Z_A]: (a, b) =>
    getUserFullName(b.createdFor).localeCompare(getUserFullName(a.createdFor)),
  [CAREER_PLAN_SORT_OPTIONS.START_DATE_OLD_NEW]: (a, b) =>
    compareUndefinedDates(a.startDate, b.startDate),
  [CAREER_PLAN_SORT_OPTIONS.START_DATE_NEW_OLD]: (a, b) =>
    compareUndefinedDates(b.startDate, a.startDate),
  [CAREER_PLAN_SORT_OPTIONS.END_DATE_OLD_NEW]: (a, b) =>
    compareUndefinedDates(a.endDate, b.endDate),
  [CAREER_PLAN_SORT_OPTIONS.END_DATE_NEW_OLD]: (a, b) =>
    compareUndefinedDates(b.endDate, a.endDate),
  [CAREER_PLAN_SORT_OPTIONS.TIME_IN_ROLE_LEAST_MOST]: (a, b) =>
    differenceInCalendarMonthsUndefined(a.endDate, a.startDate) -
    differenceInCalendarMonthsUndefined(b.endDate, b.startDate),
  [CAREER_PLAN_SORT_OPTIONS.TIME_IN_ROLE_MOST_LEAST]: (a, b) =>
    differenceInCalendarMonthsUndefined(b.endDate, b.startDate) -
    differenceInCalendarMonthsUndefined(a.endDate, a.startDate),
  [CAREER_PLAN_SORT_OPTIONS.STATUS_ASC]: getJobStatusIndex(CAREER_PLAN_SORT_OPTIONS.STATUS_ASC),
  [CAREER_PLAN_SORT_OPTIONS.STATUS_DESC]: getJobStatusIndex(CAREER_PLAN_SORT_OPTIONS.STATUS_DESC),
};

const CAREER_PLANS_COLUMNS: IColumnTable<ICareerPlanPopulated>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Employee`),
    accessor: 'member',
    maxWidth: '297px',
    minWidth: '297px',
    renderCell: (item) => {
      return (
        <NameRow>
          <UserAvatar userId={item.createdFor.id} />
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
    isFixed: true,
    position: ColumnPosition.LEFT,
  },
  {
    name: (i18n: I18n) => i18n._(t`Start date`),
    accessor: 'startDate',
    maxWidth: '144px',
    minWidth: '144px',
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.START_DATE_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.START_DATE_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    renderCell: (item) => {
      return (
        <NameRow $isInactive={item.isInactiveUser}>
          {convertToTimeString(item.startDate, TIME_FORMATS.CLASSIC)}
        </NameRow>
      );
    },
    isFixed: false,
    centerAlign: true,
    position: ColumnPosition.RIGHT,
  },
  {
    name: (i18n: I18n) => i18n._(t`End date`),
    accessor: 'endDate',
    maxWidth: '144px',
    minWidth: '144px',
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.END_DATE_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.END_DATE_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },

    renderCell: (item) => {
      return (
        <NameRow $isInactive={item.isInactiveUser}>
          {item.endDate ? convertToTimeString(item.endDate, TIME_FORMATS.CLASSIC) : ''}
        </NameRow>
      );
    },
    isFixed: false,
    centerAlign: true,
    position: ColumnPosition.RIGHT,
  },
  {
    name: (i18n: I18n) => i18n._(t`Time in role`),
    accessor: 'meta.createdDate',
    maxWidth: '144px',
    minWidth: '144px',
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.TIME_IN_ROLE_LEAST_MOST,
        label: (i18n: I18n) => i18n._(t`Least - most`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.TIME_IN_ROLE_MOST_LEAST,
        label: (i18n: I18n) => i18n._(t`Most - least`),
      },
    },
    renderCell: (item) => {
      const timeInMonths = item.startDate
        ? differenceInCalendarMonths(item.endDate ?? new Date(), item.startDate)
        : undefined;
      const timeInYears = timeInMonths ? Math.floor(timeInMonths / 12) : undefined;

      let displayTime = <></>;
      if (timeInYears && timeInYears > 0) {
        displayTime =
          timeInYears === 1 ? (
            <Trans>{timeInYears} year</Trans>
          ) : (
            <Trans>{timeInYears} years</Trans>
          );
      } else if (timeInMonths !== undefined) {
        displayTime =
          timeInMonths === 1 ? (
            <Trans>{timeInMonths} month</Trans>
          ) : (
            <Trans>{timeInMonths} months</Trans>
          );
      }

      return <NameRow $isInactive={item.isInactiveUser}>{displayTime}</NameRow>;
    },
    isFixed: false,
    centerAlign: true,
    position: ColumnPosition.RIGHT,
  },
  {
    name: (i18n: I18n) => i18n._(t`Status`),
    accessor: 'status',
    maxWidth: '144px',
    minWidth: '144px',
    sortBy: {
      asc: {
        key: CAREER_PLAN_SORT_OPTIONS.STATUS_ASC,
        label: (i18n: I18n) => i18n._(t`Status Asc`),
      },
      desc: {
        key: CAREER_PLAN_SORT_OPTIONS.STATUS_DESC,
        label: (i18n: I18n) => i18n._(t`Status Desc`),
      },
    },
    renderCell: (item) => {
      const { startDate, endDate } = item;
      const startTime = startDate?.getTime();
      const endTime = endDate?.getTime();

      const statusProps = getStatusProps(endTime, startTime);

      if (!statusProps) {
        return null;
      }

      return (
        <StatusLabel
          borderColor={statusProps.borderColor}
          backgroundColor={statusProps.backgroundColor}
        >
          <Trans>{statusProps.text}</Trans>
        </StatusLabel>
      );
    },
    isFixed: false,
    centerAlign: true,
    position: ColumnPosition.RIGHT,
  },
];

const LS_KEY = LS_KEYS.LS_JOB_DASHBOARD_TAB_EMPLOYEE;

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const initialFilters = {
  isShowFilters: false,
  search: '',
  statuses: [JOB_STATUS.ACTIVE],
  sortBy: CAREER_PLAN_SORT_OPTIONS.NAME_A_Z,
  pagination: DEFAULT_PAGINATION,
};

function Members({
  jobProfile,
  careerPlans,
  refetchCareerPlans,
}: {
  jobProfile: IJobProfilePopulated;
  careerPlans: ICareerPlanPopulated[];
  refetchCareerPlans: () => void;
}) {
  const { addToast } = useToasts();
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  const $showAssignEmployeesModal = useBoolState(false);
  const { pagination, changePagination } = usePagination(10);
  const [careerPlanToEdit, setCareerPlanToEdit] = useState<ICareerPlanPopulated>();
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const debCurrentFilters = useDebounce(currentFilters, 300);
  const dispatch = useDispatch();

  const filterCounter = () => {
    const filters = [
      currentFilters.search,
      currentFilters.statuses,
      currentFilters.selectedDateOption,
      currentFilters.createdBy,
    ];
    return filters.filter((item) => !isEmpty(item)).length || undefined;
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) =>
      setCurrentFilters((prevState: any) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION,
      })),
    onChangeFilter: (key: string, value: IMultiSelectOption) =>
      setCurrentFilters((prevState: any) => ({
        ...prevState,
        pagination: DEFAULT_PAGINATION,
        [key]: value,
      })),
    resetFilters: () => setCurrentFilters(initialFilters),
    selectedDateOption: currentFilters.selectedDateOption,
    statuses: currentFilters.statuses,
    createdBy: currentFilters.createdBy,
    filterCount: filterCounter(),
  };

  const getFilters = useCallback(() => {
    return {
      filters: {
        search: currentFilters.search,
        statuses: currentFilters.statuses
          ?.map(({ key }: IMultiSelectOption) => key)
          .filter(Boolean),
      },
      options: {
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
        index: currentFilters.pagination.index,
        sortBy: currentFilters.sortBy,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debCurrentFilters]);

  const {
    selectedItems: selectedCareerPlans,
    onSelectItem,
    isItemChecked,
    onCheckAll,
    resetSelectedItems,
  } = useMultiSelectState(careerPlans);

  const filteredCareerPlans = useMemo(() => {
    const { filters, options } = getFilters();
    return (
      careerPlans
        .filter((plan) => {
          return getUserFullName(plan.createdFor)
            .toLowerCase()
            .includes(filters?.search?.toLowerCase());
        })
        .filter(
          (plan) =>
            filters?.statuses?.length === 0 || filters?.statuses?.includes(getJobStatus(plan)),
        )
        // @ts-ignore
        .sort(sortMethods[options.sortBy])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerPlans, debCurrentFilters, pagination.index, pagination.limit]);

  async function removeSelectedCareerPlans() {
    const textConfirm = i18n._(
      t`Are you sure you want to remove ${selectedCareerPlans.length} users from this role?`,
    );
    if (await confirm(i18n, textConfirm)) {
      await removeCareerPlans(selectedCareerPlans);
      await refetchCareerPlans();
      resetSelectedItems();
      // need to update redux store and remove the career plan
      dispatch(removeCareerPlansFromReduxStore(selectedCareerPlans));
    }
  }

  async function removeSelectedCareerPlan(id: string) {
    const textConfirm = i18n._(t`Are you sure you want to remove this user from this role?`);
    if (await confirm(i18n, textConfirm)) {
      await removeCareerPlans([id]);
      await refetchCareerPlans();
      resetSelectedItems();
      // need to update redux store and remove the career plan
      dispatch(removeCareerPlansFromReduxStore([id]));
    }
  }

  async function handleAssignJob(
    employees: {
      employeeId: string;
      startDate?: Date;
      endDate?: Date;
      isPrimary: boolean;
    }[],
  ) {
    const jobsDetails = employees.map((employee) => ({
      jobProfileId: jobProfile.id,
      status: CAREER_PLAN_STATUSES.CURRENT,
      userId: employee.employeeId,
      startDate: employee.startDate,
      endDate: employee.endDate,
      primary: employee.isPrimary,
    }));

    await createCareerPlans(jobsDetails);

    await refetchCareerPlans();
  }

  return (
    <>
      <TitleBlock>
        <Title>{getMultiLangString(jobProfile.name)}</Title>
      </TitleBlock>
      <StyledTableList
        leftMinWidth="344px"
        data={filteredCareerPlans.slice(pagination.skip, pagination.index * pagination.limit)}
        columns={CAREER_PLANS_COLUMNS}
        isScrollbarVisible
        filtersProps={{
          isFiltered: !!filters.search,
          filters,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          // @ts-ignore
          filterComponents: <Filters filters={filters} />,
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount: filteredCareerPlans?.length ?? 0,
        }}
        placeholderProps={{
          emptyStateText: i18n._(t`No employees yet...`),
        }}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: CAREER_PLAN_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        menuProps={{
          isMenuVisible: true,
          createMenuItems: (item) => [
            {
              label: <Trans>Edit start or end date</Trans>,
              action: () => setCareerPlanToEdit(item),
              icon: ICONS.EDIT_PENCIL,
            },
            {
              icon: ICONS.DELETE_BIN,
              action: () => removeSelectedCareerPlan(item.id),
              isWarning: true,
              label: <Trans>Delete from role</Trans>,
            },
          ],
        }}
        actionButton={{
          label: <Trans>Assign</Trans>,
          onClick: () => {
            $showAssignEmployeesModal.on();
          },
          variant: ButtonVariant.PRIMARY,
          icon: ICONS.ADD_PLUS,
        }}
        secondaryButton={{
          label: <Trans>Export csv</Trans>,
          onClick: async () => {
            addToast({
              title: i18n._(t`Exporting CSV`),
              subtitle: i18n._(
                t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
              ),
              type: TOAST_TYPES.INFO,
            });
            await downloadCareerPlansCSV(jobProfile.id);
          },
          variant: ButtonVariant.SECONDARY,
          icon: ICONS.IMPORT,
        }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect: {
            checkedCount: selectedCareerPlans.length,
            isAllChecked: every(careerPlans.map((plans) => selectedCareerPlans.includes(plans.id))),
            onSelectItem,
            isItemChecked,
            onCheckAll,
            onDelete: () => removeSelectedCareerPlans(),
            onDeleteButtonText: i18n._(t`Remove from role`),
          },
        }}
        syncHover
        showFixedShadow
      />
      {careerPlanToEdit && (
        <StartEndDateModal
          careerPlan={careerPlanToEdit}
          onClose={(shouldRefetch = false) => {
            setCareerPlanToEdit(undefined);
            if (shouldRefetch) {
              refetchCareerPlans();
            }
          }}
        />
      )}
      {$showAssignEmployeesModal.value && (
        <AssignEmployeeModal
          jobProfile={jobProfile}
          usersToHide={careerPlans.map((plan) => plan.createdFor.id)}
          onSave={handleAssignJob}
          onClose={() => {
            $showAssignEmployeesModal.off();
          }}
        />
      )}
    </>
  );
}

export { Members };

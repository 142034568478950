import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CONNECTION_STATUSES, JOB_PROFILE_STATUSES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant, ButtonSize } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Switch from '~/components/Switch';
import { IJobProfilePopulated } from '~/pages/JobProfileViewAdmin/types';

import { createColumns } from './columns';
import { EmployeeDateSection } from './components/DateSection';
import {
  ActionContainer,
  Footer,
  HeaderTitle,
  HeaderWrapper,
  JobSettingsWrapper,
  SettingsContainer,
  StepLabel,
  StyledButton,
  StyledTableList,
  SubTitle,
  ToggleContainer,
} from './design';
import { IAssignEmployeeForm, type IEmployee, SORT_OPTIONS } from './types';
import { useResolver } from './useResolver';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { getJobProfiles } from '~/services/jobProfiles';
import { getTeams } from '~/services/teams';
import { getCompanyUsers } from '~/services/users';

import type { IJobProfile, ITeam, IUser } from '@learned/types';

interface AssignEmployeeModalProps {
  jobProfile: IJobProfilePopulated;
  onClose: () => void;
  onSave: (
    employees: {
      employeeId: string;
      startDate?: Date;
      endDate?: Date;
      isPrimary: boolean;
    }[],
  ) => Promise<void>;
  isOnlyCoachTeamMembers?: boolean;
  usersToHide?: IUser['id'][];
}

enum SECTION {
  SELECT_EMPLOYEE_SECTION = 1,
  SETTINGS_SECTION = 2,
}

export const AssignEmployeeModal = ({
  jobProfile,
  onClose,
  onSave,
  isOnlyCoachTeamMembers,
  usersToHide = [],
}: AssignEmployeeModalProps) => {
  const { i18n } = useLingui();
  const { resolver } = useResolver();
  const teamsFromStore = useSelector(getTeams);
  const $loading = useBoolState();
  const [total, setTotal] = useState<number | undefined>();
  const [users, setUsers] = useState<IEmployee[]>([]);
  const [userTeams, setUserTeams] = useState<Record<string, ITeam>>({});
  const [selectedRoles, setSelectedRoles] = useState<IJobProfile[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<ITeam[]>([]);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [roles, setRoles] = useState<IJobProfile[]>([]);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.NAME_A_Z);
  const getMultiLangString = useMultiLangString();
  const [currentSection, setCurrentSection] = useState(SECTION.SELECT_EMPLOYEE_SECTION);
  const { pagination, changePagination } = usePagination(10);
  const [search, setSearch] = useState('');
  const debSearch = useDebounce(search, 300);
  const $applySameSettingsToggle = useBoolState(true);

  const formControl = useForm<IAssignEmployeeForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      employees: [],
    },
  });
  const { control, setValue, watch, getValues, formState } = formControl;
  const employeeWatch = watch('employees');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employees',
  });

  useEffect(() => {
    const fetchTeams = async () => {
      const res = await getTeams();
      setTeams(Object.values(res));
    };

    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchJobProfiles = async () => {
      const jobProfiles = await getJobProfiles(
        {
          search: '',
          status: JOB_PROFILE_STATUSES.ACTIVE,
        },
        { limit: 20 },
      );
      setRoles(Object.values(jobProfiles));
    };

    fetchJobProfiles();
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      $loading.on();
      const sortOption = sortBy === SORT_OPTIONS.NAME_A_Z ? { fullName: 1 } : { fullName: -1 };
      const { data } = await getCompanyUsers(
        {
          search: debSearch,
          limit: pagination.limit,
          skip: pagination.skip,
          jobProfiles: selectedRoles.map((r) => r.id),
          teams: !isEmpty(selectedTeams)
            ? selectedTeams.map(({ id }) => id)
            : isOnlyCoachTeamMembers
            ? // @ts-ignore
              Object.values(teams) || []
            : [],
          sort: sortOption,
          usersToHide,
          statuses: [CONNECTION_STATUSES.ACTIVE],
        },
        ['teams', 'jobProfiles', 'coaches'],
      );

      if (!isMounted) {
        return;
      }
      const usersData: IEmployee[] = Object.values(data?.users ?? {});

      setUsers(usersData);
      setUserTeams(data?.teams);
      setTotal(data?.total ?? 0);
      $loading.off();
    };

    fetchData();

    return () => void (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debSearch,
    isOnlyCoachTeamMembers,
    pagination.limit,
    pagination.skip,
    selectedRoles,
    selectedTeams,
    sortBy,
    teamsFromStore,
    teams,
  ]);

  const filterCount = useMemo(() => {
    let count = 0;

    if (selectedTeams.length) {
      count += 1;
    }
    if (selectedRoles.length) {
      count += 1;
    }

    return count;
  }, [selectedRoles.length, selectedTeams.length]);

  const onCheckAll = useCallback(() => {
    const allUsersExist = users.every((user) =>
      fields.some(({ employeeId }) => employeeId === user.id),
    );

    setValue(
      'employees',
      allUsersExist
        ? []
        : users.map((user) => ({
            employeeId: user.id,
            isPrimary: false,
            startDate: new Date(),
          })),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, employeeWatch]);

  const isItemChecked = useCallback(
    (item: IUser) => employeeWatch.find(({ employeeId }) => employeeId === item.id),
    [employeeWatch],
  );

  const handleItemSelect = (item: IUser) => {
    const existingIndex = fields.findIndex(({ employeeId }) => employeeId === item.id);
    if (existingIndex !== -1) {
      remove(existingIndex);
    } else {
      const params = { employeeId: item.id, isPrimary: false, startDate: new Date() };
      append(params);
    }
  };

  const resetFilters = () => {
    setSearch('');
    setSelectedTeams([]);
    setSelectedRoles([]);
  };

  const onNextClick = () => {
    setCurrentSection(SECTION.SETTINGS_SECTION);
  };

  const onAssignClick = async () => {
    $loading.on();
    const { employees } = getValues();
    let employeesList = [...employees];

    if ($applySameSettingsToggle.value) {
      const employeeSettings = employees[0];

      employeesList = employees.map((emp) => ({ ...employeeSettings, employeeId: emp.employeeId }));
    }
    await onSave(employeesList);

    $loading.off();
  };

  const isDisabled = () => {
    return (
      $loading.value ||
      !(employeeWatch.length > 0) ||
      !!(currentSection === SECTION.SETTINGS_SECTION && !isEmpty(formState.errors))
    );
  };

  return (
    <Modal
      onClose={onClose}
      width={750}
      maxHeight={'883px'}
      contentStyles={{ padding: '24px 32px', margin: '0' }}
      isHideHeader
      hideFooter
      showDivider={false}
      overflow
    >
      <HeaderWrapper>
        <div>
          <HeaderTitle>
            <Trans>Assign employees</Trans>
            {currentSection === SECTION.SETTINGS_SECTION && <span>({employeeWatch.length})</span>}
          </HeaderTitle>
          <SubTitle>
            <Trans>To</Trans>: {getMultiLangString(jobProfile.name ?? '')}
          </SubTitle>
        </div>
        <Button
          type="button"
          variant={ButtonVariant.ICON}
          size={ButtonSize.BIG}
          icon={ICONS.CLOSE}
          iconSize={ICON_SIZES.LARGE}
          onClick={onClose}
        />
      </HeaderWrapper>
      {currentSection === SECTION.SELECT_EMPLOYEE_SECTION && (
        <StyledTableList
          columns={createColumns(getMultiLangString, userTeams)}
          data={users}
          menuProps={{
            isMenuVisible: false,
          }}
          isLoading={$loading.value}
          multiSelectProps={{
            isMultiSelectVisible: true,
            multiSelect: {
              checkedCount: employeeWatch.length,
              isAllChecked: every(
                users.map((user) => employeeWatch.find(({ employeeId }) => employeeId === user.id)),
              ),
              onSelectItem: handleItemSelect,
              isItemChecked,
              onCheckAll,
            },
          }}
          sortProps={{ sortBy, setSortBy }}
          filtersProps={{
            filters: {
              search,
              setSearch,
              filterCount: filterCount || undefined,
            },
            isToggleHideFilterVisible: true,
            resetFilters,
            isFiltered: !!search,
            filterComponents: (
              <>
                <Dropdown
                  isSearchable={teams.length > 7}
                  onChange={(teams) => setSelectedTeams(teams)}
                  items={teams}
                  selectedItems={selectedTeams}
                  stringifyItem={(item) => getMultiLangString(item.name)}
                  hashItem={(item) => item.id}
                  placeholder={i18n._(t`Teams`)}
                  isSingleSelect={false}
                />
                <Dropdown
                  isSearchable={teams.length > 7}
                  onChange={(roles) => setSelectedRoles(roles)}
                  items={roles}
                  selectedItems={selectedRoles}
                  stringifyItem={(item) => getMultiLangString(item.name)}
                  hashItem={(item) => item.id}
                  placeholder={i18n._(t`Jobs`)}
                  isSingleSelect={false}
                />
              </>
            ),
          }}
          paginationProps={{
            pagination,
            changePagination,
            totalCount: total,
          }}
        />
      )}
      {currentSection === SECTION.SETTINGS_SECTION && (
        <ShowSpinnerIfLoading loading={$loading.value || false}>
          <SettingsContainer>
            {employeeWatch.length > 1 && (
              <ToggleContainer>
                <Switch
                  onChange={$applySameSettingsToggle.toggle}
                  checked={$applySameSettingsToggle.value}
                />
                <Trans>Apply the same settings for all employees</Trans>
              </ToggleContainer>
            )}
            <JobSettingsWrapper>
              {!$applySameSettingsToggle.value || employeeWatch.length === 1 ? (
                employeeWatch.map((employee, index) => (
                  <EmployeeDateSection
                    index={index}
                    formControl={formControl}
                    key={employee.employeeId}
                    employee={users.find(({ id }) => id === employee.employeeId)}
                  />
                ))
              ) : (
                <EmployeeDateSection
                  index={0}
                  formControl={formControl}
                  employee={users.find(({ id }) => id === employeeWatch[0].employeeId)}
                  isGlobalSetting={$applySameSettingsToggle.value}
                />
              )}
            </JobSettingsWrapper>
          </SettingsContainer>
        </ShowSpinnerIfLoading>
      )}

      <Footer>
        <ActionContainer>
          <StyledButton
            disabled={$loading.value}
            onClick={onClose}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(t`Cancel`)}
          />
          <StepLabel>
            Step {currentSection} <span>of 2</span>
          </StepLabel>
        </ActionContainer>
        <ActionContainer>
          {currentSection === SECTION.SETTINGS_SECTION && (
            <StyledButton
              disabled={$loading.value}
              onClick={() => setCurrentSection(SECTION.SELECT_EMPLOYEE_SECTION)}
              icon={ICONS.BACK}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Back`)}
            />
          )}
          <StyledButton
            disabled={isDisabled()}
            onClick={() =>
              currentSection === SECTION.SELECT_EMPLOYEE_SECTION ? onNextClick() : onAssignClick()
            }
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            label={
              currentSection === SECTION.SELECT_EMPLOYEE_SECTION
                ? i18n._(t`Next`)
                : i18n._(t`Assign`)
            }
          />
        </ActionContainer>
      </Footer>
    </Modal>
  );
};

import React, { useEffect, useMemo } from 'react';

import {
  CAREER_PLAN_STATUSES,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_STATUS,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _, { difference, isEmpty, uniq } from 'lodash';
import qs from 'qs';
import { type UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { AssignJobModal } from '~/components/Modals/AssignJobModal';
import { AssignTeamsModal } from '~/components/Modals/AssignTeamsModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { SearchSelect } from '~/components/SearchSelect';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';
import { IAssignJobDetails } from '~/pages/UserPublicProfile/types';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSettingsRole, getUser, getUsers } from '~/selectors/baseGetters';
import { createCareerPlans } from '~/services/careerPlans';
import { addMemberToTeams } from '~/store/teams/actions';
import getUserFullName from '~/utils/getUserFullName';
import { isOldReview } from '~/utils/reviewUtils';

import {
  ParticipantSection,
  UserAvatarWrapper,
  Label,
  EmployeeHeader,
  AvatarContainer,
} from '../../design';
import { useEditEmployee } from '../../hooks/useEditEmployee';
import { errors as errorTypes } from '../../hooks/useResolver';
import { ErrorIcon } from '../ErrorIcon';
import { StepFooter } from '../StepFooter';

import type { IReviewIndividualForm, PopulatedCareerPlan } from '../../types';
import type { ITeam, IUser } from '@learned/types';

interface StepParticipantsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
}

const Wrapper = styled.div`
  padding-bottom: 2px;
  margin-bottom: -4px;
`;

const ErrorWrapper = styled.div`
  margin-left: 5px;
`;

const ParticipantsSection = ({ sectionState, formMethods }: StepParticipantsProps) => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const {
    watch,
    setValue,
    formState: { errors },
  } = formMethods;
  const watchEmployees = watch('employees');
  const $isAssignTeamModalOpen = useBoolState(false);
  const $isAssignJobModalOpen = useBoolState(false);

  const { formMethods: employeeFormMethods } = useEditEmployee({
    employee: watchEmployees[0],
  });

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const userRole = useSelector(getSettingsRole);
  const currentUser = useSelector(getUser);
  const companyUsers: { [key: string]: IUser } = useSelector(getUsers);
  const userId = query.user;

  const user = Object.values(companyUsers).find((user) => user.id === userId);

  const coaches = watch('employees')[0]?.coaches || [];
  const isDraft = watch('status') === REVIEW_STATUS.DRAFT;

  useEffect(() => {
    const formData = employeeFormMethods.getValues();
    const selectedData = {
      ...watchEmployees[0],
      careerPlans: formData.careerPlans.selected,
      coaches: formData.coaches.selected?.map((item) => item.id),
      guests: formData.guests.selected?.map((item) => item.id),
    };

    setValue('employees', [selectedData], { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(employeeFormMethods?.getValues())]);

  const onDeleteEmployee = async () => {
    const isConfirm = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Delete employee?`),
      description: i18n._(
        t`Are you sure you want to delete this employee from the review? The review, including provided input, will be lost. This cannot be undone.`,
      ),
    });
    if (isConfirm) {
      setValue('employees', []);
    }
  };

  const options = useMemo(() => {
    return {
      showCoaches: !!watch('evaluators')
        ?.map((item) => item.value)
        ?.includes(REVIEW_QUESTION_EVALUATORS.COACH),
      showJobs: !!watch('reviewQuestionTypes')?.some((type) =>
        [REVIEW_QUESTION_TYPES.SKILL_CATEGORY, REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1].includes(
          type,
        ),
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([watch('evaluators'), watch('reviewQuestionTypes')])]);

  const generateErrorMessage = ({
    errorType,
    errorMessage,
  }: {
    errorType?: string;
    errorMessage?: string;
  }) => {
    let message = i18n._(t`Validation error`);

    if (!errorMessage) {
      return message;
    }

    switch (errorType) {
      case errorTypes.noJob:
        message = errorMessage;
        break;
      case errorTypes.noSkill:
        message = errorMessage
          .split('|')
          .map((jobName) =>
            i18n._(
              t`Job ${jobName} required a minimum 1 skill in at least one of the enabled skill-categories`,
            ),
          )
          .join('\n');
        break;
      case errorTypes.noFocusArea:
        message = errorMessage
          .split('|')
          .map((skill) => i18n._(t`Skill ${skill} does not contain focus areas`))
          .join('\n');
        break;
      default:
        break;
    }

    return message;
  };

  const getJobsErrorMessage = () => {
    const careerPlanError = errors.employees && errors.employees[0]?.careerPlans;

    if (careerPlanError) {
      return generateErrorMessage({
        errorType: careerPlanError?.type,
        errorMessage: careerPlanError?.message,
      });
    }

    return undefined;
  };

  const getCoachesErrorMessage = () => {
    const hasErrors = errors?.employees && errors.employees[0]?.coaches;
    const hasCoaches = !isEmpty(coaches);

    if (hasErrors && !hasCoaches) {
      return i18n._(t`Select at least one coach`);
    }
  };

  const onCoachesChange = async (selected: IUser[]) => {
    const removedItems = difference(employeeFormMethods.watch('coaches.selected'), selected);

    if (removedItems.length > 0 && !isDraft) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Delete coach?`),
        description: i18n._(
          t`Are you sure you want to delete this coach from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });
      if (!isConfirmed) {
        return;
      }
    }

    employeeFormMethods.setValue('coaches.selected', selected);
  };

  const handleAssignTeamClick = () => {
    $isAssignTeamModalOpen.on();
  };

  const handleAssignJobClick = () => {
    $isAssignJobModalOpen.on();
  };

  const handleAddUserToTeam = async (selectedTeams: ITeam[]) => {
    const teams = selectedTeams.map(({ id }) => id).filter(Boolean);

    await dispatch(
      addMemberToTeams(watch('employees')[0].id, uniq(teams), true, {
        sendRequestAsAdmin: (currentUser as unknown as IUser).isAdmin,
      }),
    );

    const newCoachIdList = selectedTeams
      .map(({ coaches }) => coaches)
      .flat()
      .filter((coachId) => coachId !== watch('employees')[0].id);

    const newCoaches = newCoachIdList.map((coach: string) => companyUsers[coach]);

    setValue('employees', [
      {
        ...watchEmployees[0],
        availableCoaches: uniq([...watchEmployees[0].availableCoaches, ...newCoachIdList]),
      },
    ]);

    employeeFormMethods.setValue(
      'guests.selected',
      uniq([...employeeFormMethods.watch('guests.selected'), ...newCoaches]),
    );

    $isAssignTeamModalOpen.off();
  };

  const updateCareerPlansInModal = async (selectedJobProfiles: PopulatedCareerPlan[]) => {
    setValue('employees', [
      {
        ...watchEmployees[0],
        availableCareerPlans: uniq([
          ...watchEmployees[0].availableCareerPlans,
          ...selectedJobProfiles,
        ]),
      },
    ]);

    employeeFormMethods.setValue('careerPlans.selected', [
      ...employeeFormMethods.watch('careerPlans.selected'),
      ...selectedJobProfiles,
    ]);
  };

  const handleAddJobToUser = async (selectedJobProfiles: IAssignJobDetails[]) => {
    const jobsData = selectedJobProfiles.map((job) => ({
      jobProfileId: job.id,
      status: CAREER_PLAN_STATUSES.CURRENT,
      userId: watchEmployees[0].id,
      startDate: job.startDate,
      endDate: job.endDate,
      primary: job.isPrimary,
    }));

    const { data } = await createCareerPlans(jobsData, userRole);

    updateCareerPlansInModal(data.careerPlans as unknown as PopulatedCareerPlan[]);

    $isAssignJobModalOpen.off();
  };

  const guestsError = errors?.employees?.[0]?.guests;
  const errorMessage =
    guestsError?.type === errorTypes.noQuestions ? guestsError?.message : undefined;

  return (
    <>
      <Wrapper>
        <ParticipantSection>
          <EmployeeHeader className="title">
            <Trans>Employee</Trans>
            {!isOldReview(watch('fetchedReview.version')) && (
              <Button
                variant={ButtonVariant.PRIMARY}
                label={i18n._(t`Add`)}
                disabled={!isEmpty(watchEmployees)}
              />
            )}
          </EmployeeHeader>
          <UserAvatarWrapper>
            <AvatarContainer>
              <UserAvatar userId={watchEmployees[0]?.id} />
              {errorMessage && (
                <ErrorWrapper>
                  <ErrorIcon message={errorMessage} />
                </ErrorWrapper>
              )}
            </AvatarContainer>
            {!isOldReview(watch('fetchedReview.version')) && isDraft && (
              <Button
                variant={ButtonVariant.ICON_DELETE}
                icon={ICONS.DELETE_BIN}
                onClick={onDeleteEmployee}
                disabled={!isEmpty(user)}
              />
            )}
          </UserAvatarWrapper>
        </ParticipantSection>
        {options.showJobs && (
          <ParticipantSection>
            <div className="title">
              <Trans>Jobs</Trans>
            </div>
            <div className="inputWrapper">
              <Label>
                <Trans>Select job(s) to review</Trans>
              </Label>
              <ToolTip
                disabled={isDraft}
                tooltip={i18n._(
                  t`After a review has been published, the selected job(s) cannot be changed`,
                )}
              >
                <div>
                  <SearchSelect
                    selectedItems={employeeFormMethods.watch('careerPlans.selected')}
                    onChange={(selected) =>
                      employeeFormMethods.setValue('careerPlans.selected', selected)
                    }
                    onSearchChange={(search) =>
                      employeeFormMethods.setValue('careerPlans.search', search)
                    }
                    items={employeeFormMethods.watch('careerPlans.filtered')}
                    stringifyItem={(careerPlan) => getMultiLangString(careerPlan.jobProfile.name)}
                    placeholder={i18n._(t`Search job(s)`)}
                    error={getJobsErrorMessage()}
                    isExpandable
                    disabled={!isDraft}
                    noResultsPlaceholder={i18n._(t`No job assigned`)}
                    actions={
                      (currentUser as unknown as IUser).isAdmin
                        ? [
                            {
                              handler: handleAssignJobClick,
                              name: i18n._(t`Assign job`),
                              icon: ICONS.ADD_PLUS,
                            },
                          ]
                        : []
                    }
                  />
                </div>
              </ToolTip>
            </div>
          </ParticipantSection>
        )}
        {options.showCoaches && (
          <ParticipantSection>
            <div className="title">
              <Trans>Coaches</Trans>
            </div>
            <div className="inputWrapper">
              <Label>
                <Trans>Select coach(es) who will provide</Trans>
              </Label>
              <SearchSelect
                selectedItems={employeeFormMethods.watch('coaches.selected')}
                onChange={onCoachesChange}
                onSearchChange={(search) => employeeFormMethods.setValue('coaches.search', search)}
                items={employeeFormMethods.watch('coaches.filtered')}
                stringifyItem={(user) => getUserFullName(user)}
                placeholder={i18n._(t`Search coach(es)`)}
                error={getCoachesErrorMessage()}
                isExpandable
                showAvatar
                noResultsPlaceholder={i18n._(t`No team assigned`)}
                actions={
                  (currentUser as unknown as IUser).isAdmin
                    ? [
                        {
                          handler: handleAssignTeamClick,
                          name: i18n._(t`Assign team`),
                          icon: ICONS.ADD_PLUS,
                        },
                      ]
                    : []
                }
              />
            </div>
          </ParticipantSection>
        )}
        <ParticipantSection>
          <div className="title">
            <Trans>Guests</Trans>
          </div>
          <div className="inputWrapper">
            <Label>
              <Trans>Select guests invited to the review</Trans>
            </Label>
            <SearchSelect
              selectedItems={employeeFormMethods.watch('guests.selected')}
              onChange={(selected) => employeeFormMethods.setValue('guests.selected', selected)}
              onSearchChange={(search) => employeeFormMethods.setValue('guests.search', search)}
              items={employeeFormMethods.watch('guests.filtered')}
              stringifyItem={(user) => getUserFullName(user)}
              placeholder={i18n._(t`Search guests`)}
              minCharsToShowDropdown={2}
              isExpandable
              showAvatar
            />
          </div>
        </ParticipantSection>
      </Wrapper>

      <StepFooter
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
      />
      {$isAssignTeamModalOpen.value && (
        <AssignTeamsModal
          employee={watch('employees')[0] as unknown as IUser}
          onClose={$isAssignTeamModalOpen.off}
          onSubmit={handleAddUserToTeam}
        />
      )}
      {$isAssignJobModalOpen.value && (
        <AssignJobModal
          employee={watchEmployees[0]}
          onClose={$isAssignJobModalOpen.off}
          onSubmit={handleAddJobToUser}
        />
      )}
    </>
  );
};

export { ParticipantsSection };

import React, { ChangeEvent, useEffect, type Dispatch, type SetStateAction } from 'react';

import { FocusAreaType, Locals_all } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import nanoid from 'nanoid';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { useSkillCategories } from '~/pages/SkillSetsOverview/SkillForm/hooks';
import { IGeneralForm } from '~/pages/SkillSetsOverview/SkillForm/types';
import { ISkillGenerated } from '~/pages/SkillSetsOverview/types';

import type { ISelectedSkillTemplate } from '~/@types/job';
import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { createSkill, generateSkill } from '~/services/skills';
import { getCompanyLocales, LOCALES_ARRAY } from '~/utils/companyLanguages';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { removeEmptyValues } from '~/utils/multiLangString';

import { MoveSkillCategory } from '../components/MoveSkillCategory';
import { SkillsSelector } from '../components/SkillsSelector';
import {
  StyledSearchField,
  StyledSearchFieldWrapper,
  Title,
  Header,
  Content,
  Actions,
  Subtitle,
  Footer,
  TitleContainer,
  HeaderActionContainer,
  EmptyPlaceholder,
  ResultsContainer,
  CancelAndStepper,
  Step,
  StepWrapper,
} from '../design';

import type { IForm } from '../types';
import type { IMultiLangString, ISkill, ISkillTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

export interface SearchCompanySkillsProps<T extends IForm> {
  onClose: () => void;
  skillCategoryName: string;
  skillCategoryId: string;
  searchInputValue: string;
  setSearchInputValue: Dispatch<SetStateAction<string>>;
  skillNames?: (ISkillTemplate | ISkill)[];
  companySkills?: ISkill[];
  templateSkills?: (ISkill | ISkillTemplate)[];
  defaultFocusAreaLevel: number | null;
  isDefaultFocusAreaLevelEnabled?: boolean;
  setIsSelectLevelAndFocusAreasVisible: Dispatch<SetStateAction<boolean>>;
  setSource: Dispatch<SetStateAction<ISkillTemplate | ISkill | undefined>>;
  formMethods: UseFormReturn<T>;
  setSkillTemplates?: Dispatch<SetStateAction<ISkillTemplate[]>>;
  setSkills?: Dispatch<SetStateAction<ISkill[]>>;
  jobName?: string;
  resultInOtherCategories?: ISkill[];
  isCustomSkill?: boolean;
  refreshCompanySkills?: () => void;
  source?: ISkillTemplate | ISkill;
  isSearchLoading: { on: () => void; off: () => void; value: boolean };
  setSelectedSkill?: Dispatch<SetStateAction<ISkill | undefined>>;
  selectedSkill?: ISkill;
}

function SearchCompanySkills<T extends IForm>({
  onClose,
  skillCategoryName,
  skillCategoryId,
  searchInputValue,
  setSearchInputValue,
  skillNames,
  companySkills = [],
  templateSkills = [],
  defaultFocusAreaLevel,
  isDefaultFocusAreaLevelEnabled,
  setIsSelectLevelAndFocusAreasVisible,
  setSource,
  formMethods,
  setSkillTemplates,
  setSkills,
  jobName,
  resultInOtherCategories,
  isCustomSkill,
  refreshCompanySkills,
  source,
  isSearchLoading,
  setSelectedSkill,
  selectedSkill,
}: SearchCompanySkillsProps<T>) {
  const { i18n } = useLingui();
  const { setValue, watch } = formMethods;
  const getMultiLangString = useMultiLangString();
  const languageState = useLanguageState();

  const { skillCategories } = useSkillCategories();
  const $isAIGenerationLoading = useBoolState(false);

  // @ts-ignore
  const watchSkills = watch(`skills.${skillCategoryId}.skills`) as ISelectedSkillTemplate['skills'];

  // get already selected skill ids to an array
  // @ts-ignore
  const selectedSkillsIds = Object.values(watch('skills'))
    .flatMap((skill: any) => skill.skills)
    .map((skill: any) => ('skill' in skill ? skill.skill : skill.skillTemplate));

  const filteredResultsInOtherCategories = resultInOtherCategories?.filter(
    (skill) => !selectedSkillsIds.includes(skill.id),
  );

  useEffect(() => {
    // check if the selected skill is in filteredResultsInOtherCategories
    if (selectedSkill) {
      const doesSelectedSkillExist = filteredResultsInOtherCategories?.find(
        (skill) => skill.id === selectedSkill?.id,
      );
      if (!doesSelectedSkillExist || !searchInputValue) {
        setSelectedSkill?.(undefined);
        setSource(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredResultsInOtherCategories, searchInputValue]);

  const addSkill = (source: ISkillTemplate | ISkill) => {
    const focusAreas = source.focusAreas;
    if (defaultFocusAreaLevel && isDefaultFocusAreaLevelEnabled) {
      let level = defaultFocusAreaLevel;

      if (focusAreas.length < defaultFocusAreaLevel) {
        level = focusAreas.length;
      }

      if (focusAreas.length) {
        // @ts-ignore
        if (source?.company) {
          setSkills?.((skills) => uniqBy(skills.concat(source as ISkill), ({ id }) => id));
        } else {
          setSkillTemplates?.((prevSkillTemplates) =>
            uniqBy(prevSkillTemplates.concat(source as ISkillTemplate), ({ id }) => id),
          );
        }

        const currentSkills = watchSkills.filter((watchSkill) => {
          if ('company' in source) {
            const skill = source as ISkill;
            return skill.id !== watchSkill.skill;
          } else {
            const skillTemplate = source as ISkillTemplate;
            return watchSkill.skillTemplate !== skillTemplate.id;
          }
        });

        // @ts-ignore
        setValue(`skills.${skillCategoryId}.skills`, [
          ...currentSkills,
          {
            selectedFocusAreas: [
              {
                type: FocusAreaType.SELECT_LEVEL,
                level: level - 1,
              },
            ],
            // @ts-ignore
            ...(source?.company
              ? {
                  skill: source.id,
                }
              : { skillTemplate: source.id }),
          },
        ]);
      }
    }
  };

  // Using the same existing logic to convert generated focus areas
  const formatFocusAreas = (generatedSkill: ISkillGenerated) => {
    const newFocusAreas: Array<{
      level: number;
      values: Array<{ name: IMultiLangString; _id: string }>;
    }> = [];

    Object.keys(generatedSkill.focusAreas).forEach((locale) => {
      if (newFocusAreas.length === 0) {
        const size = generatedSkill.focusAreas[locale as Locals_all].length;
        new Array(size).fill(true).map((_, index) =>
          newFocusAreas.push({
            level: index,
            values: [],
          }),
        );
      }

      generatedSkill.focusAreas[locale as Locals_all].forEach((focusAreaGenerated, index) => {
        newFocusAreas[index].level = focusAreaGenerated.level;

        if (newFocusAreas[index].values.length === 0) {
          new Array(focusAreaGenerated.values.length).fill(true).map(() =>
            newFocusAreas[index].values.push({
              name: {},
              _id: nanoid(),
            }),
          );
        }

        focusAreaGenerated.values.forEach((value, i) => {
          newFocusAreas[index].values[i].name = {
            ...newFocusAreas[index].values[i].name,
            [locale as Locals_all]: value,
          };
        });
      });
    });

    return newFocusAreas;
  };

  const convertToGeneralForm = (
    generatedSkill: ISkillGenerated,
    skillCategory: string,
  ): IGeneralForm => {
    const newFocusAreas = formatFocusAreas(generatedSkill);

    return {
      // Convert name and description to arrays of ILanguageValue
      name: Object.entries(generatedSkill.name).map(([locale, value]) => ({
        locale,
        value,
      })),
      description: Object.entries(generatedSkill.description).map(([locale, value]) => ({
        locale,
        value,
      })),
      skillCategory,
      focusAreas: newFocusAreas,
      jobProfiles: {},
    };
  };

  const handleAICreation = async () => {
    $isAIGenerationLoading.on();
    const { data }: { data: { skill: ISkillGenerated } } = await generateSkill({
      name: searchInputValue,
      locales: getCompanyLocales(languageState, LOCALES_ARRAY.PRIMARY_FIRST),
      skillCategory: skillCategoryId,
    });
    const skillCategory = skillCategories.find(({ id }) => id === skillCategoryId);
    const typeConvertedData = convertToGeneralForm(data.skill, skillCategoryId);

    const predicate = ({ value, locale }: { value: string; locale: string }) =>
      value !== '' || locale === '_id';
    const transformedData = {
      ...data,
      name: convertLanguageValue(typeConvertedData.name.filter(predicate)),
      description: convertLanguageValue(typeConvertedData.description.filter(predicate)),
      jobProfiles: Object.entries(typeConvertedData.jobProfiles).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.map((jp) => jp.id) };
      }, {}),
      skillCategory: skillCategoryId,
      published: true,
      focusAreas: typeConvertedData.focusAreas
        .map(({ values, level }) => ({
          values: values.map(({ name }) => ({
            name: removeEmptyValues(name),
          })),
          level,
        }))
        .slice(0, skillCategory?.skillLevels?.length ?? 5),
    };

    await createSkill(transformedData, transformedData.jobProfiles);
    refreshCompanySkills?.();
    isSearchLoading.off();
  };

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>
            <Trans>Add skill or KPI</Trans>
          </Title>
          <Subtitle>
            <Trans>
              To category:{' '}
              {selectedSkill?.skillCategory
                ? getMultiLangString(selectedSkill?.skillCategoryName ?? '')
                : skillCategoryName}{' '}
              for {jobName}
            </Trans>
          </Subtitle>
        </TitleContainer>
        <HeaderActionContainer>
          <Button size={ButtonSize.MEDIUM} onClick={onClose} variant={ButtonVariant.CLOSE} />
        </HeaderActionContainer>
      </Header>
      <Content>
        <StyledSearchFieldWrapper>
          <StyledSearchField
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchInputValue(e.currentTarget.value)
            }
            value={searchInputValue}
            placeholder={i18n._(t`Search skill or KPI`)}
            style={{
              width: '100%',
              borderRadius: '10rem',
              fontSize: '14px',
            }}
          />
        </StyledSearchFieldWrapper>

        <ShowSpinnerIfLoading loading={isSearchLoading.value} height="351px">
          <ResultsContainer isCustomSkill={isCustomSkill}>
            {searchInputValue &&
            skillNames?.length === 0 &&
            filteredResultsInOtherCategories?.length === 0 ? (
              <EmptyPlaceholder>
                <Trans>No skills found</Trans>
                <Button
                  variant={ButtonVariant.TEXT_PRIMARY}
                  label={i18n._(t`Create ${searchInputValue}`)}
                  icon={ICONS.ADD_PLUS}
                  onClick={() => handleAICreation()}
                  isLoading={$isAIGenerationLoading.value}
                  disabled={$isAIGenerationLoading.value}
                />
              </EmptyPlaceholder>
            ) : (
              <SkillsSelector
                skills={
                  searchInputValue
                    ? skillNames?.filter((skill) => !selectedSkillsIds.includes(skill.id)) ?? []
                    : companySkills.filter((skill) => !selectedSkillsIds.includes(skill.id))
                }
                watchSkills={watchSkills}
                onSelectSkill={(skill) => {
                  if (!isDefaultFocusAreaLevelEnabled) {
                    setSource(skill);
                  } else {
                    addSkill(skill);
                    onClose();
                  }
                }}
                isCustomSkill={isCustomSkill}
                source={source}
              />
            )}

            {!isEmpty(filteredResultsInOtherCategories) &&
              searchInputValue &&
              skillNames?.length === 0 &&
              // filter out already selected skills
              filteredResultsInOtherCategories?.map((skill) => (
                <MoveSkillCategory
                  skill={skill}
                  key={skill.id}
                  skillName={getMultiLangString(skill.name ?? '')}
                  searchTerm={searchInputValue}
                  currentSkillCategory={getMultiLangString(skill.skillCategoryName ?? '')}
                  moveToCategory={skillCategoryName}
                  moveToCategoryId={skillCategoryId}
                  selectedSkill={selectedSkill}
                  setSelectedSkill={setSelectedSkill}
                  addSkill={addSkill}
                  refreshCompanySkills={refreshCompanySkills}
                  isSearchLoading={isSearchLoading}
                  setSource={setSource}
                />
              ))}
          </ResultsContainer>
          {!isCustomSkill && (
            <SkillsSelector
              title={i18n._(t`Recommendations`)}
              skills={templateSkills?.filter((skill) => !selectedSkillsIds.includes(skill.id))}
              watchSkills={watchSkills}
              onSelectSkill={(skill) => {
                if (!isDefaultFocusAreaLevelEnabled) {
                  setSource(skill);
                } else {
                  addSkill(skill);
                  onClose();
                }
              }}
              isRecommendedSection
              source={source}
            />
          )}
        </ShowSpinnerIfLoading>
      </Content>
      <Footer>
        <Actions>
          <CancelAndStepper>
            <Button
              label={i18n._(t`Cancel`)}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={onClose}
            />
            <StepWrapper>
              <Trans>
                Step 1 <Step>of 2</Step>
              </Trans>
            </StepWrapper>
          </CancelAndStepper>
          <Button
            label={i18n._(t`Next`)}
            type="submit"
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              setIsSelectLevelAndFocusAreasVisible(true);
            }}
            disabled={!source}
          />
        </Actions>
      </Footer>
    </>
  );
}

export { SearchCompanySkills };

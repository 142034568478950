import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import isEmpty from 'lodash/isEmpty';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';

import {
  BodyArea,
  ButtonCtr,
  Cell,
  Header,
  IconCtr,
  LastColumn,
  LeftItems,
  RightItems,
  RightItemsCtr,
  Row,
  RowCtr,
  ScrollWrapper,
  SkillCategoryCtr,
  Subtitle,
  Title,
  TitleRow,
  Wrapper,
} from './CategoriesModal.design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getCompanySettings } from '~/selectors/companySettings';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';
import { isNotNil } from '~/utils/typePredicates';

import { MODEL_TYPE } from '../../constants';

import type { INewSkillCategory, TCategories, TCategoriesObj, TFlagOptions } from '../../types';

type TProps = {
  flags: TFlagOptions[];
  title: string;
  subtitle: string;
  onCancel: () => void;
  inputText: string;
  type: MODEL_TYPE;
  skillCategoryList: TCategoriesObj;
  onSkillCategoryUpdate: (categories: TCategories[], closeModal?: boolean) => void;
  onDelete?: (categoryId: string) => void;
  loader: boolean;
  languageState: ILanguageStateReturn;
};

export const CategoriesModal = ({
  title,
  subtitle,
  onCancel,
  type,
  skillCategoryList,
  onSkillCategoryUpdate,
  onDelete,
  loader,
  languageState,
}: TProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const companySettings = useSelector(getCompanySettings);

  const {
    control,
    setValue,
    watch,
    register,
    trigger,
    formState: { errors },
  } = useForm<{
    categories: TCategories[];
  }>({
    mode: 'all',
    defaultValues: {
      categories: [],
    },
  });

  const { fields, move, remove, append } = useFieldArray({
    control,
    name: 'categories',
  });

  const languages = languageState.companyLanguages.filter(({ locale }) =>
    languageState.languages.find((language) => language.locale === locale),
  );

  useEffect(() => {
    setValue(
      'categories',
      companySettings.skillCategories
        .map((id: string) => skillCategoryList[id])
        .filter(isNotNil)
        .map((category: INewSkillCategory) => {
          return {
            label: getMultiLangString(category.name || ''),
            customLabels: turnMultiLangIntoArray(category.name, languageState.companyLanguages),
            category,
            updated: false,
            created: false,
          };
        }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategoryList]);

  const isFirstType =
    type === MODEL_TYPE.EDIT_FOCUS_AREA || type === MODEL_TYPE.EDIT_SKILL_CATEGORY;

  const createSkillCategory = async () => {
    if (!errors) {
      await onSkillCategoryUpdate(watch('categories'), false);
    }

    append({
      label: '',
      customLabels: languages.map(({ locale }) => ({ locale, value: '' })),
      updated: true,
      created: true,
    });
  };

  const handleSubmit = async () => {
    await trigger();

    if (isEmpty(errors)) {
      onSkillCategoryUpdate(watch('categories'));
    }
  };

  return (
    <Wrapper minSize={isFirstType ? '600px' : '400px'}>
      <Header>
        <Title>
          {title}
          {isFirstType && (
            <IconCtr onClick={onCancel}>
              <Icon icon={ICONS.CLOSE} size={18} />
            </IconCtr>
          )}
        </Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <BodyArea>
        <RowCtr>
          <TitleRow>
            <LeftItems>{i18n._(t`Default label`)}</LeftItems>
            <RightItems>{i18n._(t`Custom label`)}</RightItems>
            <LastColumn />
          </TitleRow>
          <ScrollWrapper>
            <DragDropContext
              onDragEnd={(result) => {
                move(result.source.index, result?.destination?.index || 0);
              }}
            >
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((category, i) => (
                      <Draggable key={category.id} draggableId={category.id} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Row
                              key={`category-${i + 1}`}
                              className={snapshot.isDragging ? 'active' : ''}
                            >
                              <LeftItems>
                                <div className="content">
                                  <Icon
                                    icon={ICONS.GRAB_GRID}
                                    size={ICON_SIZES.SMALL}
                                    className="dragIcon"
                                  />
                                  <span className="label">{category.label}</span>
                                </div>
                              </LeftItems>
                              <RightItemsCtr>
                                <RightItems>
                                  {languages.map(({ locale }, flagIndex) => (
                                    <Cell key={`label-${flagIndex + 1}`}>
                                      <Input
                                        register={register(
                                          `categories.${i}.customLabels.${flagIndex}.value`,
                                          {
                                            required: i18n._(t`This is a required field`),
                                            onChange: () => {
                                              setValue(`categories.${i}.updated`, true);
                                              trigger(
                                                `categories.${i}.customLabels.${flagIndex}.value`,
                                              );
                                            },
                                          },
                                        )}
                                        placeholder={i18n._(t`Name`)}
                                        leftIcon={getUnicodeFlagIcon(locale.split('_')[1])}
                                        error={
                                          errors.categories?.[i]?.customLabels?.[flagIndex]?.value
                                            ?.message
                                        }
                                        width="100%"
                                        height="38px"
                                      />
                                    </Cell>
                                  ))}
                                </RightItems>
                                <LastColumn>
                                  {!category?.category?.skillCategoryTemplate && (
                                    <Button
                                      type="button"
                                      variant={ButtonVariant.ICON_DELETE}
                                      size={ButtonSize.MEDIUM}
                                      onClick={() => {
                                        if (category?.category?.id) {
                                          onDelete?.(category.category.id);
                                        } else {
                                          remove(i);
                                        }
                                      }}
                                    />
                                  )}
                                </LastColumn>
                              </RightItemsCtr>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ScrollWrapper>
          <SkillCategoryCtr>
            <Button
              disabled={false}
              label={i18n._(t`Create skill category`)}
              variant={ButtonVariant.SECONDARY}
              onClick={createSkillCategory}
              size={ButtonSize.MEDIUM}
            />
          </SkillCategoryCtr>
        </RowCtr>

        <ButtonCtr isSpaceBetween={!isFirstType}>
          <Button
            disabled={false}
            label={i18n._(t`Cancel`)}
            variant={ButtonVariant.SECONDARY}
            onClick={onCancel}
            size={ButtonSize.MEDIUM}
          />
          <Button
            disabled={false}
            isLoading={loader}
            label={i18n._(t`Save`)}
            variant={ButtonVariant.PRIMARY}
            onClick={handleSubmit}
            size={ButtonSize.MEDIUM}
          />
        </ButtonCtr>
      </BodyArea>
    </Wrapper>
  );
};

export default CategoriesModal;

import React, { useMemo, useEffect } from 'react';

import { IReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TableGrid } from '~/components/TableGrid';
import { TSecondaryHeaderColumn } from '~/components/TableGrid/types';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { MENU_SIZE } from '~/pages/Reports/options';

import { TableBodyWrapper } from './design';
import TableTopControlsSection from './TableTopControlsSection';

import { IColumnTable } from '~/@types/table';
import routes from '~/constants/routes';
import { type Pagination } from '~/hooks/usePagination';
import { TSortingOrder } from '~/services/reports';
import { TGetReviewResults, downloadReviewResults } from '~/services/reviews/reports/results';
import history from '~/utils/history';

import { TReviewResultRow } from '../../types';

interface IResultsAverageTableProps {
  review: IReview | undefined;
  onAdvancedReportNavigation: () => void;
  toggleTableViewSize: () => void;
  isMaximizedView: boolean;
  isNonWeightedReview?: boolean;
  useReviewResultsAverage: {
    filters: { search: string };
    setFilters: (filters: { search: string }) => void;
    getRatingsDataPayload: TGetReviewResults | undefined;
    setGetRatingsDataPayload: (payload: TGetReviewResults) => void;
    totalCount: number;
    pagination: Pagination['pagination'];
    changePagination: (pagination: Pagination['pagination']) => void;
    columns: IColumnTable<any>[];
    sortBy: string;
    setSortBy: (sortBy: string) => void;
    isLoading: boolean;
    secondaryHeaderColumns: TSecondaryHeaderColumn[] | undefined;
    heatMapData: TReviewResultRow[];
    setHeatMapData: (data: TReviewResultRow[]) => void;
  };
}

/**
 * Displayed for reviews created after Learned 3.0
 * Review Cycle -> Results -> Questions
 */
const ResultsAverageTable: React.FC<IResultsAverageTableProps> = ({
  review,
  onAdvancedReportNavigation,
  isMaximizedView = false,
  toggleTableViewSize,
  useReviewResultsAverage,
  isNonWeightedReview = false,
}) => {
  const { i18n } = useLingui();
  const {
    filters,
    setFilters,
    getRatingsDataPayload,
    setGetRatingsDataPayload,
    totalCount,
    pagination,
    changePagination,
    columns,
    sortBy,
    setSortBy,
    isLoading,
    secondaryHeaderColumns,
    heatMapData,
    setHeatMapData,
  } = useReviewResultsAverage;

  const { addToast } = useToasts();

  const customPaginationList = useMemo(
    () => [
      { id: 50, label: i18n._(t`50 per page`) },
      { id: 100, label: i18n._(t`100 per page`) },
    ],
    [i18n],
  );

  const exportCSV = async () => {
    if (!getRatingsDataPayload) {
      return;
    }
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    const { sorting, reviewId } = getRatingsDataPayload;

    await downloadReviewResults(
      {
        reviewId,
        sorting,
        options: {},
        measure: 'theme',
      },
      'csv',
      'download',
    );
  };

  const onChangeFilters = (key: string, value: unknown) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  useEffect(() => {
    if (!review) {
      return;
    }

    const sortedArray = sortBy.split('__') || [];

    const getRatingsDataPayload = {
      filters: {
        search: filters.search,
      },
      sorting: {
        orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
        order: sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
      },
      options: pagination,
      reviewId: review.id,
      measure: 'theme',
    } as TGetReviewResults;
    setGetRatingsDataPayload(getRatingsDataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), pagination, review, sortBy]);

  const onRowClick = (clickedItem: TReviewResultRow) => {
    // average row
    if (clickedItem.id === 'companyAverage') {
      return;
    }
    // team row
    else if (clickedItem.nestedLevel === 0) {
      const rowsUpdated = heatMapData.map((item) => {
        const isTeam = item.nestedLevel === 0;
        const isTeamMatch = isTeam && item.temporalUniqueId === clickedItem.temporalUniqueId;
        const isEmployeeFromTeam =
          !isTeam && (item as TReviewResultRow).parents?.includes(clickedItem.temporalUniqueId);
        if (isTeamMatch) {
          return {
            ...item,
            isCollapsed: !item.isCollapsed,
          };
        }
        if (isEmployeeFromTeam) {
          return {
            ...item,
            isVisible: !item.isVisible,
          };
        }
        return item;
      });

      setHeatMapData(rowsUpdated);
    }
    // member row
    else if (clickedItem.nestedLevel === 1 && clickedItem.userReviewId) {
      history.push(
        routes.USER_REVIEW_DASHBOARD.build(
          {},
          { userReviewId: clickedItem.userReviewId, isBackPath: true },
        ),
      );
    }
  };

  return (
    <TableBodyWrapper>
      <TableTopControlsSection
        filters={filters}
        onExportCSV={exportCSV}
        isCSVExportVisible={totalCount > 0}
        isMaximizedView={isMaximizedView}
        onToggleTableViewSize={toggleTableViewSize}
        onChangeFilters={onChangeFilters}
        onAdvancedReportNavigation={onAdvancedReportNavigation}
      />

      <TableGrid
        isHeatmapColored={true}
        data={heatMapData?.filter((item) => item.isVisible) || []}
        columns={columns}
        isScrollbarVisible
        rightMinWidth={`${MENU_SIZE.ONLY_AVERAGE}px`}
        isLoading={isLoading}
        secondaryHeaderColumns={secondaryHeaderColumns}
        enableMultipleTableHeaderRows={true}
        onRowClick={onRowClick}
        onColClick={{
          column: 'primaryDimension',
          onClick: onRowClick,
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
          customPaginationList,
        }}
        leftMinWidth={`${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`}
        setSortBy={(value) => {
          setSortBy(value);
        }}
        showTopArea={false}
        sortBy={sortBy}
        isLeftColumnsStriped
        placeholderProps={{
          emptyStateText: isNonWeightedReview
            ? i18n._(
                t`This review is based on a template that does not measure weighted employee performance. You can find the ratings per question on the tab: Questions`,
              )
            : '',
        }}
      />
    </TableBodyWrapper>
  );
};

export default ResultsAverageTable;

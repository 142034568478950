import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { REVIEW_QUESTION_EVALUATORS, REVIEW_QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { size, uniq, values } from 'lodash';
import { useFieldArray, type UseFormReturn, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { IconNames } from '~/components/IconOld';
import { isRequiredRatedQuestion } from '~/pages/Reviews/utils';
import { QuestionModal } from '~/pages/ReviewThemeSetup/components/QuestionModal';
import { QuestionPreviewModal } from '~/pages/ReviewThemeSetup/components/QuestionPreviewModal';
import { SkillModal } from '~/pages/ReviewThemeSetup/components/SkillModal';
import {
  IQuestionForm,
  ISelectedSkill,
  ISkill,
  IThemeQuestionDefaultData,
} from '~/pages/ReviewThemeSetup/types';
import {
  convertQuestionOptions,
  getEvaluators,
  getLabelsForAvailableLanguages,
  getQuestionTypeObject,
  getRatingLabels,
} from '~/pages/ReviewThemeSetup/utils';

import { Footer, Form, StyledTitle } from './design';
import { ImportQuestionsModal } from './ImportQuestionsModal';
import { QuestionsEmpty } from './QuestionsEmpty';
import { QuestionsList } from './QuestionsList';

import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { createReviewQuestion, updateReviewQuestion } from '~/services/reviewQuestions';
import { getReviewTheme } from '~/services/reviewThemes';
import { getSkillCategories } from '~/services/skillCategories';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IGeneralForm, PopulatedQuestion } from '../../types';
import type {
  IMultiLangString,
  IReviewQuestion,
  IReviewQuestionCustomSkillSettings,
  IReviewQuestionDefaultData,
  IReviewQuestionGoalPlanSettings,
  IReviewQuestionSkillCategorySettings,
} from '@learned/types';

interface IQuestionsProps {
  invalidOptionalQuestions?: Record<string, PopulatedQuestion>;
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: ILanguageStateReturn;
  questions: Record<IReviewQuestion['id'], IReviewQuestion & { themeName?: IMultiLangString }>;
  setQuestions: Dispatch<SetStateAction<Record<string, IReviewQuestion>>>;
  reviewTemplateId: string;
  refreshTemplate: () => void;
  questionIdToOpen?: string;
  setEditQuestion: (questionId?: string) => void;
  autoSave: () => void;
  equalizeWeights: (themeIds?: string[]) => void;
  themeIds: string[];
  getWeightedThemes: (themeIds: string[], questions?: IReviewQuestion[]) => string[];
}

export interface ISelectedQuestion {
  questionId: string;
  index: number;
  theme?: string;
}

const Questions = ({
  invalidOptionalQuestions,
  formMethods,
  setCurrentSection,
  languageState,
  refreshTemplate,
  reviewTemplateId,
  autoSave,
  equalizeWeights,
  themeIds,
  setQuestions,
  questions,
  getWeightedThemes,
}: IQuestionsProps) => {
  const $isLoading = useBoolState(false);
  const { control } = formMethods;
  const { i18n } = useLingui();
  const $isImportQuestion = useBoolState(false);
  // Todo: implement
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addQuestion, setAddQuestion] = useState<boolean>(false);
  const [index, setIndex] = useState<number | undefined>();
  const indexItemToAdd = 0;
  const $isSkillModalOpen = useBoolState(false);

  const [updatedQuestions, setUpdatedQuestions] = useState<IReviewQuestion[]>([]);
  const [skillCategories, setSkillCategories] = useState<
    { value: string; label: Record<string, string>; type: string }[]
  >([]);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [selectedQuestionToEdit, setSelectedQuestionToEdit] = useState<
    IQuestionForm | null | undefined
  >(null);
  const getString = useMultiLangString();
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [previewQuestion, setPreviewQuestion] = useState<
    | (IQuestionForm & {
        themeIcon: IconNames;
        themeIconColor: string;
        themeName: IMultiLangString;
      })
    | undefined
  >(undefined);
  const [selectedQuestions, setSelectedQuestions] = useState<ISelectedQuestion[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);

  const questionIdsWatch = useWatch({ control, name: 'questions' });

  const questionIdsField = useFieldArray({ name: 'questions', control });

  const [selectedTheme, setSelectedTheme] = useState<string | null>(null);

  const goBack = () => setCurrentSection(0);
  const goNext = () => setCurrentSection(2);

  const createNewQuestion = (index?: number) => {
    setIndex(index);
    setAddQuestion(true);
  };

  const reEvaluateWeights = (
    currentThemeIds: string[],
    updatedThemeIds: string[],
    questions: IReviewQuestion[],
  ) => {
    const existingWeightedThemes = getWeightedThemes(currentThemeIds);
    const currentWeightedThemes = getWeightedThemes(updatedThemeIds, questions);

    if (existingWeightedThemes.length !== currentWeightedThemes.length) {
      equalizeWeights(updatedThemeIds);
    }
  };

  useEffect(() => {
    reEvaluateWeights(
      themeIds,
      [...themeIds, ...uniq(updatedQuestions.map((q) => q.theme))],
      updatedQuestions,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, updatedQuestions]);

  const addQuestions = (...questions: IReviewQuestion[]) => {
    const questionIds = questions.map((question) => ({
      questionId: question.id,
      theme: question.theme,
    }));
    const questionsMap = questions.reduce(
      (acc, question) => ({ ...acc, [question.id]: question }),
      {},
    );
    setQuestions((prevState) => ({ ...prevState, ...questionsMap }));
    questionIdsField.insert(
      index !== undefined ? index + 1 : size(questionIdsField.fields),
      questionIds,
    );

    setUpdatedQuestions(questions);

    autoSave();
  };

  const updateQuestions = (...questions: IReviewQuestion[]) => {
    const questionsMap = questions.reduce(
      (acc, question) => ({ ...acc, [question.id]: question }),
      {},
    );

    setQuestions((prevState) => ({ ...prevState, ...questionsMap }));
    setUpdatedQuestions(questions);
    autoSave();
  };

  const removeQuestion = (index: number, item: IReviewQuestion) => {
    questionIdsField.remove(index);
    setQuestions((prevState) => {
      const newState = { ...prevState };
      delete newState[item.id];
      return newState;
    });
    const themeRemoved =
      values(questions)
        .filter(
          (question) =>
            item.theme === question.theme && isRequiredRatedQuestion(question as IReviewQuestion),
        )
        .map((q) => q.theme).length === 1;
    if (themeRemoved) {
      equalizeWeights(themeIds.filter((id) => id !== item.theme));
    }
    autoSave();
  };

  const reorderQuestions = (oldIndex: number, newIndex: number) => {
    questionIdsField.move(oldIndex, newIndex);
  };

  const handleSubmit = (value: IQuestionForm, reviewThemeId?: string) => {
    if (!reviewThemeId) {
      return;
    }
    if (selectedQuestionToEdit) {
      editQuestion(value, reviewThemeId);
    } else {
      createQuestion(value, reviewThemeId);
    }
  };

  const createQuestion = async (value: IQuestionForm, reviewThemeId?: string) => {
    if (reviewThemeId) {
      const evaluators = Object.keys(value.settings.evaluators)
        .map((key: string) =>
          value.settings?.evaluators?.[key as keyof typeof value.settings.evaluators] ? key : null,
        )
        .filter(Boolean);

      const {
        data: { reviewQuestion },
      } = await createReviewQuestion({
        position: indexItemToAdd,
        // @ts-ignore
        question: {
          theme: reviewThemeId === 'OTHER' ? '' : reviewThemeId,
          type: value.type.key as REVIEW_QUESTION_TYPES,
          name: convertLanguageValue(value.name),
          description: convertLanguageValue(value.description),
          settings: {
            options: value?.settings?.isManualScale
              ? getRatingLabels(languageState, value?.options)
              : null,
            evaluators,
            isCommentsAllowed: value.settings.isCommentsAllowed,
            isCommentsObligated: value.settings.isCommentsObligated,
            isAnswerObligated: !value.settings.isAnswerObligated,
            isMeasurementReversed: value.settings.isMeasurementReversed,
            isManualScale: value.settings.isManualScale,
            skillCategory:
              value.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY &&
              value.skillOrKpiCategory?.id,
            skills: value.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && value.settings.skills,
            subTypes: value.settings.subTypes,
          },
        },
      });
      const reviewQuestionWithTheme = await mapThemeToNewQuestion(reviewThemeId, reviewQuestion);
      addQuestions(reviewQuestionWithTheme);
      autoSave();
    }
  };

  const mapThemeToNewQuestion = async (themeId: string, reviewQuestion: IReviewQuestion) => {
    if (reviewQuestion.themeName && reviewQuestion.themeIcon && reviewQuestion.themeColor) {
      return reviewQuestion;
    }
    const themeData = await getReviewTheme(themeId);
    reviewQuestion.themeName = themeData.data.reviewTheme.name;
    reviewQuestion.themeIcon = themeData.data.reviewTheme.icon;
    reviewQuestion.themeColor = themeData.data.reviewTheme.iconColor;
    return reviewQuestion;
  };

  const editQuestion = async (value: IQuestionForm, reviewThemeId: string) => {
    if (selectedQuestionToEdit) {
      const evaluators = Object.keys(value.settings.evaluators)
        .map((key: string) =>
          value.settings?.evaluators?.[key as keyof typeof value.settings.evaluators] ? key : null,
        )
        .filter(Boolean);

      const {
        data: { reviewQuestion },
      } = await updateReviewQuestion(selectedQuestionToEdit.id!, {
        type: value.type.key as REVIEW_QUESTION_TYPES,
        theme: reviewThemeId === 'OTHER' ? '' : reviewThemeId,
        name: convertLanguageValue(value.name),
        description: convertLanguageValue(value.description),
        settings: {
          options: value?.settings?.isManualScale
            ? getRatingLabels(languageState, value?.options)
            : null,
          evaluators,
          isCommentsAllowed: value.settings.isCommentsAllowed,
          isCommentsObligated: value.settings.isCommentsObligated,
          isAnswerObligated: !value.settings.isAnswerObligated,
          isMeasurementReversed: value.settings.isMeasurementReversed,
          isManualScale: value.settings.isManualScale,
          skillCategory:
            value.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && value.skillOrKpiCategory?.id,
          skills: value.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && value.settings.skills,
          subTypes: value.settings.subTypes,
        },
      });
      const reviewQuestionWithTheme = await mapThemeToNewQuestion(reviewThemeId, reviewQuestion);
      updateQuestions(reviewQuestionWithTheme);
      autoSave();
    }
  };

  const handleSelectedSkillSubmit = (selectedSkill: ISelectedSkill) => {
    setSelectedSkills([
      ...selectedSkills.filter(({ skill }: ISelectedSkill) => skill !== selectedSkill.skill),
      selectedSkill,
    ]);
    $isSkillModalOpen.off();
  };

  const handleEdit = (questionId: string) => {
    setAddQuestion(true);
    const question = questions[questionId];

    if (question?.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      setSelectedSkills((question?.settings?.skills as ISelectedSkill[]) || []);
    }

    const questionType = getQuestionTypeObject(question.type);

    const nameLabels = turnMultiLangIntoArray(question.name, languageState.companyLanguages);
    const descriptionLabels = turnMultiLangIntoArray(
      question.description || {},
      languageState.companyLanguages,
    );

    setSelectedQuestionToEdit({
      id: question.id,
      name: getLabelsForAvailableLanguages(languageState, nameLabels),
      description: getLabelsForAvailableLanguages(languageState, descriptionLabels),
      theme: question?.theme,
      type: questionType!,
      settings: {
        isCommentsAllowed:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsAllowed ?? false,
        isCommentsObligated:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsObligated ?? false,
        isMeasurementReversed:
          (question.settings as IThemeQuestionDefaultData)?.isMeasurementReversed ?? false,
        isManualScale: (question.settings as IThemeQuestionDefaultData)?.isManualScale ?? false,
        evaluators: getEvaluators(
          (question.settings as IThemeQuestionDefaultData)
            .evaluators as REVIEW_QUESTION_EVALUATORS[],
        ),
        skillCategory:
          (question.settings as IReviewQuestionSkillCategorySettings)?.skillCategory || '',
        skills: (question.settings as IReviewQuestionCustomSkillSettings)?.skills,
        isAnswerObligated:
          !(question.settings as IReviewQuestionDefaultData)?.isAnswerObligated || false,
        subTypes: (question.settings as IReviewQuestionGoalPlanSettings)?.subTypes || undefined,
      },
      options: convertQuestionOptions(question, languageState.companyLanguages),
    });
  };

  const currentCompany = useSelector(getCurrentCompany);

  useEffect(() => {
    const fetchSkillCategories = async () => {
      const response = await getSkillCategories();
      setSkillCategories(
        Object.values(response).map((skillCatgory) => {
          const category = skillCatgory as {
            id: string;
            name: Record<string, string>;
            skillLevels: Record<string, string>[];
            type: string;
          };
          return {
            value: category.id,
            label: category.name,
            levels: category.skillLevels?.length,
            type: category.type,
          };
        }),
      );
    };

    fetchSkillCategories();
  }, [currentCompany]);

  const handleQuestionModalClose = () => {
    setAddQuestion(false);
    setSelectedQuestionToEdit(null);
  };

  const handleDeleteFromQuestionModal = (id: IQuestionForm['id']) => {
    if (!id) {
      return;
    }
    const index = questionIdsField.fields.findIndex((question) => question.questionId === id);
    const question = questions[id];

    if (!question || index === -1) {
      return;
    }
    removeQuestion(index, question);
    handleQuestionModalClose();
  };

  const getPreviewEvaluators = () => {
    if (!previewQuestion) {
      return [];
    }
    previewQuestion.settings.evaluators;
    const evaluators: REVIEW_QUESTION_EVALUATORS[] = [];
    if (previewQuestion.settings.evaluators.employee) {
      evaluators.push(REVIEW_QUESTION_EVALUATORS.EMPLOYEE);
    }
    if (previewQuestion.settings.evaluators.peer) {
      evaluators.push(REVIEW_QUESTION_EVALUATORS.PEER);
    }
    if (previewQuestion.settings.evaluators.coach) {
      evaluators.push(REVIEW_QUESTION_EVALUATORS.COACH);
    }
    return evaluators;
  };

  const handleSelectAllQuestions = () => {
    let allQuestions: ISelectedQuestion[] = [];
    if (questionIdsField.fields.length !== selectedQuestions.length) {
      allQuestions = questionIdsField.fields
        .filter((question) => !!question.id)
        .map((question, index) => {
          return {
            questionId: question.questionId as string,
            theme: question.theme,
            index,
          };
        });
    }

    setSelectedQuestions(allQuestions);
  };

  const handleQuestionSelect = (id: string) => {
    const index = questionIdsField.fields.findIndex((question) => question.questionId === id);

    if (!id || index === -1) {
      return;
    }

    const selectedQuestion = selectedQuestions.find(({ questionId }) => questionId === id);

    if (selectedQuestion) {
      setSelectedQuestions(
        selectedQuestions.filter(({ questionId }) => questionId !== selectedQuestion.questionId),
      );
    } else {
      setSelectedQuestions([
        ...selectedQuestions,
        {
          questionId: id,
          index,
          theme: questionIdsField.fields[index].theme,
        },
      ]);
    }
  };

  const checkIsItemSelected = (questionId?: string) => {
    return selectedQuestions.findIndex((question) => questionId === question.questionId) >= 0;
  };

  const getThemesList = (questions: { [key: string]: { theme: string } }) => {
    return uniq(
      Object.values(questions)
        .map(({ theme }) => theme)
        .filter(Boolean),
    );
  };

  const handleMultipleDeleteConfirm = async () => {
    $isLoading.on();
    if (selectedQuestions.length > 0) {
      const existingThemes = getThemesList(questions);

      const updatedQuestions = Object.values(questions)
        .filter(
          (question) => !selectedQuestions.find(({ questionId }) => questionId === question.id),
        )
        .reduce((acc, question) => {
          // @ts-ignore
          acc[question.id] = question;
          return acc;
        }, {});

      const themesAfterUpdating = getThemesList(updatedQuestions);

      const removedThemes = existingThemes.filter(
        (themeId) => !themesAfterUpdating.includes(themeId),
      );

      if (removedThemes.length > 0) {
        reEvaluateWeights(existingThemes, themesAfterUpdating, Object.values(updatedQuestions));
      }

      setQuestions(updatedQuestions);
      questionIdsField.remove(selectedQuestions.map(({ index }) => index));
      setSelectedQuestions([]);
      autoSave();
    }
    $isLoading.off();
  };

  return (
    <Form>
      <StyledTitle>
        <Trans>Questions</Trans>
      </StyledTitle>
      {questionIdsField.fields.length === 0 ? (
        <QuestionsEmpty
          createNewQuestion={createNewQuestion} // Todo: implement
          importQuestions={$isImportQuestion.on}
        />
      ) : (
        <QuestionsList
          isLoading={$isLoading.value}
          invalidOptionalQuestions={invalidOptionalQuestions}
          questionIdsList={questionIdsField.fields}
          // @ts-ignore
          items={questionIdsField.fields
            .map((field) => questions?.[field.questionId])
            .filter(Boolean)}
          createNewQuestion={createNewQuestion}
          skillCategories={skillCategories}
          importQuestions={(index) => {
            setIndex(index);
            $isImportQuestion.on();
          }}
          languageState={languageState}
          onReorderQuestions={reorderQuestions}
          setEditQuestion={handleEdit}
          removeQuestion={removeQuestion}
          autosave={autoSave}
          setSelectedSkills={setSelectedSkills}
          selectedQuestions={selectedQuestions}
          handleSelectAllQuestions={handleSelectAllQuestions}
          handleQuestionSelect={handleQuestionSelect}
          checkIsItemSelected={checkIsItemSelected}
          handleMultipleDeleteConfirm={handleMultipleDeleteConfirm}
        />
      )}
      <Footer>
        <Button
          label={i18n._(t`Back`)}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.TEXT_PRIMARY}
          onClick={goBack}
          icon={ICONS.BACK}
          iconSize={ICON_SIZES.SMALL}
        />
        <Button
          label={i18n._(t`Next`)}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.NAVIGATION_PRIMARY}
          onClick={goNext}
          isLoading={$isLoading.value}
        />
      </Footer>
      {$isImportQuestion.value && (
        <ImportQuestionsModal
          questionsToHide={questionIdsWatch.map((field) => field.questionId)}
          languageState={languageState}
          onSubmit={async () => {
            $isImportQuestion.off();
            await refreshTemplate();
          }}
          onClose={$isImportQuestion.off}
          index={index}
          createNewQuestion={createNewQuestion}
          reviewTemplateId={reviewTemplateId}
        />
      )}
      {addQuestion && (
        <QuestionModal
          languageState={languageState}
          onClose={handleQuestionModalClose}
          onDelete={handleDeleteFromQuestionModal}
          onSubmit={handleSubmit}
          setIsSkillModalOpen={$isSkillModalOpen.set}
          skillCategories={skillCategories}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          selectTheme={true}
          selectedQuestionToEdit={selectedQuestionToEdit}
          setCurrentStep={setCurrentStep}
          selectedTheme={selectedTheme ?? null}
          setSelectedTheme={setSelectedTheme}
          setPreviewQuestion={setPreviewQuestion}
        />
      )}
      {!!previewQuestion && (
        <QuestionPreviewModal
          question={previewQuestion}
          evaluators={getPreviewEvaluators()}
          onClose={() => setPreviewQuestion(undefined)}
          themeName={getString(previewQuestion.themeName)}
          themeIcon={previewQuestion.themeIcon}
          iconColor={previewQuestion.themeIconColor}
          themeId={selectedTheme}
        />
      )}
      {$isSkillModalOpen.value && (
        <SkillModal
          onClose={() => $isSkillModalOpen.off()}
          onSubmit={handleSelectedSkillSubmit}
          setSelectedSkill={setSelectedSkill}
          selectedSkill={selectedSkill}
          skills={skills}
          setSkills={setSkills}
          selectedSkills={selectedSkills}
          currentStep={currentStep}
        />
      )}
    </Form>
  );
};

export { Questions };

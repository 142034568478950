import React, { useState, useRef, useEffect } from 'react';

import { IReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { HeaderTabs } from '~/components/HeaderTabs';

import { useReviewResultAverage } from './hooks/useReviewResultAverage';
import { useReviewResultQuestions } from './hooks/useReviewResultQuestions';
import ResultsAverageTable from './ResultsAverageTable';
import ResultsQuestionsTable from './ResultsQuestionsTable';

import { getSettingsRole } from '~/selectors/baseGetters';

import { CardsSection } from '../components/CardsSection';
import { RCD_RESULTS_TABS_ENUM } from '../types';

import type { I18n } from '@lingui/core';

const ResultsTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const ResultsTabBodyWrapper = styled.div`
  margin-top: 40px;
  width: 1000px;
  &.maximized-view {
    width: calc(100% - 64px);
  }
`;

const HeaderWrapper = styled.div`
  background-color: #fff;
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

interface IResultsTabNewViewProps {
  review: IReview;
  reportId?: string;
  onGoToReportClick: () => void;
}

const ResultsTabNewView = ({ review, onGoToReportClick }: IResultsTabNewViewProps) => {
  const userRole = useSelector(getSettingsRole);
  const [isMaximizedView, setIsMaximizedView] = useState(false);
  const [currentTab, setCurrentTab] = useState<RCD_RESULTS_TABS_ENUM | ''>('');

  const useReviewResultsAverage = useReviewResultAverage();
  const useReviewResulstQuestions = useReviewResultQuestions();

  const componentRef = useRef<HTMLDivElement>(null);

  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`Average`),
      key: RCD_RESULTS_TABS_ENUM.AVERAGE,
    },
    {
      label: (i18n: I18n) => i18n._(t`Questions`),
      key: RCD_RESULTS_TABS_ENUM.QUESTIONS,
    },
  ];

  const toggleTableViewSize = () => {
    if (componentRef.current) {
      if (isMaximizedView) {
        setIsMaximizedView(false);
      } else {
        setIsMaximizedView(true);
      }
    }
  };

  const handleChangeTab = (key: string) => {
    window.location.hash = `results#${key}`;
  };

  useEffect(() => {
    const currentHash = window.location.hash.split('#').pop();
    if (currentHash) {
      if (
        currentHash === RCD_RESULTS_TABS_ENUM.AVERAGE &&
        currentTab !== RCD_RESULTS_TABS_ENUM.AVERAGE
      ) {
        setCurrentTab(RCD_RESULTS_TABS_ENUM.AVERAGE);
      } else if (
        currentHash === RCD_RESULTS_TABS_ENUM.QUESTIONS &&
        currentTab !== RCD_RESULTS_TABS_ENUM.QUESTIONS
      ) {
        setCurrentTab(RCD_RESULTS_TABS_ENUM.QUESTIONS);
      } else {
        setCurrentTab(RCD_RESULTS_TABS_ENUM.AVERAGE);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  // if there is no any themeWeight in the template, that means the entire review is not weighted
  const isNonWeightedReview =
    // isCustomWeight is false means theme weighting is disabled.
    !review.backup?.reviewTemplate.isCustomWeight ||
    // it doesn't find any theme with weight other than 0 also hide cards
    !Object.entries(review.backup?.reviewTemplate.themeWeights || {})?.find(
      (theme) => theme[1] !== 0,
    );

  return (
    <ResultsTabWrapper ref={componentRef}>
      {!isNonWeightedReview && (
        <CardsSection viewAs={userRole} review={review} isMaximizedView={isMaximizedView} />
      )}
      <ResultsTabBodyWrapper className={isMaximizedView ? 'maximized-view' : ''}>
        <HeaderWrapper>
          {/* @ts-ignore */}
          <HeaderTabs tabs={TABS} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
        </HeaderWrapper>
        {currentTab === RCD_RESULTS_TABS_ENUM.AVERAGE && (
          <ResultsAverageTable
            review={review}
            onAdvancedReportNavigation={onGoToReportClick}
            toggleTableViewSize={toggleTableViewSize}
            isMaximizedView={isMaximizedView}
            useReviewResultsAverage={useReviewResultsAverage}
            isNonWeightedReview={isNonWeightedReview}
          />
        )}
        {currentTab === RCD_RESULTS_TABS_ENUM.QUESTIONS && (
          <ResultsQuestionsTable
            review={review}
            onAdvancedReportNavigation={onGoToReportClick}
            toggleTableViewSize={toggleTableViewSize}
            isMaximizedView={isMaximizedView}
            useReviewResultQuestions={useReviewResulstQuestions}
          />
        )}
      </ResultsTabBodyWrapper>
    </ResultsTabWrapper>
  );
};

export default ResultsTabNewView;
